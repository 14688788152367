<template>
  <div class="pl-3 pr-3 pb-3 pt-0">
    <b-row class="bg-white">
      <b-col class="px-0">
        <b-table
          class="mb-0"
          ref="tablecustom"
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(article_no)="data">
            <b-row>
              <b-col class="text-left">
              
                <div class="text-name">{{ data.item.article_no }}</div>
               
                <div class="text-name ">Barcode: {{ data.item.barcode }}   <span v-if="data.item.serial">
                    Serial: ({{ data.item.serial }})
                  </span></div>  
               
              
                <div>
                  {{ data.item.product_name }}
                  <span v-if="data.item.serial">
                    ({{ data.item.serial }})
                  </span>
                </div>
                <div v-if="data.item.promotion_list.length > 0">
                  Promotion:

                  <span
                    v-for="(promotion, j) in data.item.promotion_list"
                    :key="j"
                    >{{
                      promotion.coupon_code ? `#${promotion.coupon_code}` : ""
                    }}
                    {{ promotion.promotion_name }} ลด
                    {{ promotion.discount_price }}
                    {{
                      parseInt(j) === data.item.promotion_list.length - 1
                        ? ""
                        : ","
                    }}
                  </span>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(price)="data">
            <div>{{ data.item.price | numeral("0,0.00") }}</div>
          </template>
          <template v-slot:cell(promotion_price)="data">
            <div>{{ data.item.promotion_price | numeral("0,0.00") }}</div>
          </template>
          <template v-slot:cell(sum_discount_price)="data">
            <div>{{ data.item.sum_discount_price | numeral("0,0.00") }}</div>
          </template>
          <template v-slot:cell(after_discount_price)="data">
            <div>{{ data.item.after_discount_price | numeral("0,0.00") }}</div>
          </template>
          <template v-slot:custom-foot>
            <tr class="footer-table">
              <th>Net Price (Baht)</th>
              <th class="text-center">{{ total_quantity }}</th>
              <th class="text-center">{{ total_price | numeral("0,0.00") }}</th>
              <th class="text-center">
                {{ promotion_price | numeral("0,0.00") }}
              </th>
              <th class="text-center">
                {{ discount_price | numeral("0,0.00") }}
              </th>
              <th class="text-center">
                {{ after_discount_price | numeral("0,0.00") }}
              </th>
            </tr>
          </template>
        </b-table></b-col
      ></b-row
    >
  </div>
</template>
<script>
export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      total_quantity: 0,
      total_price: 0,
      promotion_price: 0,
      discount_price: 0,
      after_discount_price: 0,
    };
  },
  created() {
    this.getTotalCount();
  },
  methods: {
    getTotalCount() {
      for (const item of this.items) {
        this.total_quantity = this.total_quantity + item.quantity;
        this.total_price = this.total_price + item.price;
        this.promotion_price = this.promotion_price + item.promotion_price;
        this.discount_price = this.discount_price + item.sum_discount_price;
        this.after_discount_price =
          this.after_discount_price + item.after_discount_price;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-name {
  font-weight: 600;
  color: #000;
}
.footer-table {
  background-color: var(--theme-secondary-color);
  color: var(--primary-color);
}
</style>
